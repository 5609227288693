import React from 'react'
//import PropTypes from 'prop-types'

const SubscriptionsPanel = props => {
    if (props.showpanel==='true') {
        return (
            <div className="zl_bottom_label_subscriptions zl_bottom_yellow_label_content">
                <h3 className="zl_bottom_label_heading">Artists</h3>
                <h2 className="zl_bottom_label_sub_heading">{props.count}</h2>
            </div>
        )
    } else {
        return(<></>)
    }
}

// SubscriptionsPanel.propTypes = {

// }

export default SubscriptionsPanel
