
import { Link } from 'react-router-dom';

import logo from '../image/AS-logo.png';

const TopNav = () => {
    return (

        <section className="zl_header_section">
            <div className="container">
                <header className="zl_header_content">
                    <a href="../../" className="zl_header_logo">
                        <img src={logo} alt="label logo" className="zl_header_logo" />
                    </a>
                    <input id="zl_toggel_checkbox" type="checkbox" className="zl_toggel_checkbox" />
                    <label htmlFor="zl_toggel_checkbox" className="zl_header_toggel_btn">
                        <svg xmlns="http://www.w3.org/2000/svg" className="zl_header_toggel_icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </label>
                    <ul className="zl_header_nav">
                        <li className="zl_header_items">
                            <a href="/../../" className="zl_header_links">Home</a>
                        </li>
                        <li className="zl_header_items">
                            <a href="/../../home/features" className="zl_header_links">Support a Project</a>
                        </li>
                        {/*<li className="zl_header_items">*/}
                        {/*    <a href="/../../home/shop" className="zl_header_links">Shop</a>*/}
                        {/*</li>*/}
                        <li className="zl_header_items">
                            <a href="/../../home/news" className="zl_header_links">News</a>
                        </li>
                        <li className="zl_header_items">
                            <a href="/../../home/featuredartists" className="zl_header_links">Artists</a>
                        </li>
                        <li className="zl_header_items">
                        
                            <a href="/../../about" className="zl_header_links">About</a>
                        </li>
                       
                        {/*<li className="zl_header_items zl_mobile_header_items">*/}
                        {/*    <Link to="index.html" className="zl_header_links">Log Out</Link>*/}
                        {/*</li>*/}
                        {/*<li className="zl_header_items zl_mobile_header_items">*/}
                        {/*    <Link to="myaccount" className="zl_header_links">My Account</Link>*/}
                        {/*</li>*/}
                        {/*<li className="zl_header_items zl_mobile_header_items">*/}
                        {/*    <Link to="index.html" className="zl_header_links">Cart</Link>*/}
                        {/*</li>*/}
                        {/*<div className="zl_header_search_social_btn">*/}
                        {/*    <div className="zl_header_social_btn">*/}
                        {/*        <a href=" " className="zl_header_social_link">*/}
                        {/*            <svg className="zl_header_social_icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path className="group-hover:fill-white"*/}
                        {/*                    d="M12.5609 4.73651C12.5698 4.8566 12.5698 4.97672 12.5698 5.09681C12.5698 8.75979 9.68279 12.9804 4.40611 12.9804C2.78046 12.9804 1.27031 12.5257 0 11.7365C0.230974 11.7623 0.453033 11.7708 0.692893 11.7708C2.03425 11.7708 3.26904 11.3333 4.25509 10.587C2.99366 10.5613 1.93655 9.76349 1.57233 8.66544C1.75001 8.69116 1.92766 8.70832 2.11423 8.70832C2.37183 8.70832 2.62947 8.67399 2.8693 8.61397C1.55458 8.3566 0.568506 7.24142 0.568506 5.8946V5.8603C0.950473 6.06618 1.39467 6.19486 1.86545 6.212C1.09261 5.71444 0.58628 4.86518 0.58628 3.90439C0.58628 3.3897 0.728386 2.91788 0.977133 2.50611C2.38958 4.18749 4.51269 5.28551 6.89339 5.40562C6.84898 5.19974 6.82232 4.9853 6.82232 4.77084C6.82232 3.24386 8.10152 2 9.69162 2C10.5178 2 11.2639 2.33456 11.7881 2.875C12.4365 2.75491 13.0584 2.52328 13.6091 2.20588C13.3959 2.84928 12.9429 3.38972 12.3477 3.73284C12.9251 3.67282 13.4848 3.51837 14 3.30393C13.6092 3.85293 13.1206 4.34188 12.5609 4.73651Z"*/}
                        {/*                    fill="#000000">*/}
                        {/*                </path>*/}
                        {/*            </svg>*/}
                        {/*        </a>*/}
                        {/*        <a href=" " className="zl_header_social_link">*/}
                        {/*            <svg className="zl_header_social_icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path className="group-hover:fill-white"*/}
                        {/*                    d="M10.0806 7.875L10.4735 5.34133H8.01679V3.69715C8.01679 3.00398 8.35997 2.32832 9.46026 2.32832H10.5771V0.171172C10.5771 0.171172 9.5636 0 8.59457 0C6.57138 0 5.24893 1.21352 5.24893 3.41031V5.34133H3V7.875H5.24893V14H8.01679V7.875H10.0806Z"*/}
                        {/*                    fill="#000000">*/}
                        {/*                </path>*/}
                        {/*            </svg>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*    <div className="zl_header_search">*/}
                        {/*        <input type="text" className="zl_header_search_input" placeholder="Search..." />*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" className="zl_header_search_icon"*/}
                        {/*            fill="none" viewBox="0 0 24 24" stroke="currentColor">*/}
                        {/*            <path stroke="#00bad9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"*/}
                        {/*                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </ul>
                    <div className="zl_header_right_content">
                        {/*<div className="zl_header_desktop_search_social_btn">*/}
                        {/*    <div className="zl_header_social_btn">*/}
                        {/*        <a href=" " className="zl_header_social_link">*/}
                        {/*            <svg className="zl_header_social_icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path className="group-hover:fill-white"*/}
                        {/*                    d="M12.5609 4.73651C12.5698 4.8566 12.5698 4.97672 12.5698 5.09681C12.5698 8.75979 9.68279 12.9804 4.40611 12.9804C2.78046 12.9804 1.27031 12.5257 0 11.7365C0.230974 11.7623 0.453033 11.7708 0.692893 11.7708C2.03425 11.7708 3.26904 11.3333 4.25509 10.587C2.99366 10.5613 1.93655 9.76349 1.57233 8.66544C1.75001 8.69116 1.92766 8.70832 2.11423 8.70832C2.37183 8.70832 2.62947 8.67399 2.8693 8.61397C1.55458 8.3566 0.568506 7.24142 0.568506 5.8946V5.8603C0.950473 6.06618 1.39467 6.19486 1.86545 6.212C1.09261 5.71444 0.58628 4.86518 0.58628 3.90439C0.58628 3.3897 0.728386 2.91788 0.977133 2.50611C2.38958 4.18749 4.51269 5.28551 6.89339 5.40562C6.84898 5.19974 6.82232 4.9853 6.82232 4.77084C6.82232 3.24386 8.10152 2 9.69162 2C10.5178 2 11.2639 2.33456 11.7881 2.875C12.4365 2.75491 13.0584 2.52328 13.6091 2.20588C13.3959 2.84928 12.9429 3.38972 12.3477 3.73284C12.9251 3.67282 13.4848 3.51837 14 3.30393C13.6092 3.85293 13.1206 4.34188 12.5609 4.73651Z"*/}
                        {/*                    fill="#000000">*/}
                        {/*                </path>*/}
                        {/*            </svg>*/}
                        {/*        </a>*/}
                        {/*        <a href=" " className="zl_header_social_link">*/}
                        {/*            <svg className="zl_header_social_icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path className="group-hover:fill-white"*/}
                        {/*                    d="M10.0806 7.875L10.4735 5.34133H8.01679V3.69715C8.01679 3.00398 8.35997 2.32832 9.46026 2.32832H10.5771V0.171172C10.5771 0.171172 9.5636 0 8.59457 0C6.57138 0 5.24893 1.21352 5.24893 3.41031V5.34133H3V7.875H5.24893V14H8.01679V7.875H10.0806Z"*/}
                        {/*                    fill="#000000">*/}
                        {/*                </path>*/}
                        {/*            </svg>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*    <div className="zl_header_desktop_search">*/}
                        {/*        <input type="text" className="zl_header_desktop_search_input" />*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" className="zl_header_search_icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">*/}
                        {/*            <path stroke="#00bad9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"*/}
                        {/*                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<ul className="zl_header_right_nav">*/}
                            <li className="zl_header_right_items">
                            <a href="/./../account/logout" className="zl_header_right_link">Log Out</a>
                            </li>
                         <li className="zl_header_items">
                            <a href="../../../myaccount" className="zl_header_links">My Account</a>
                        </li>
                        {/*    <li className="zl_header_right_items">*/}
                        {/*        <a href="index.html" className="zl_header_right_link">Cart</a>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                    </div>
                </header>
            </div>
        </section>


        // <nav>
        //     <div>
        //         <div>
        //             <a href="/">
        //              <img src="https://www.artistshare.com/Content/images/logo.png" alt="artistshare" />
        //             </a>
        //             <div data-target="navMenu">
        //                 <span><LoginButton /> </span>
        //                 <span><Link to="/">MY ACCOUNT</Link></span>
        //                 <span> <CartButton /></span>
        //             </div>
        //         </div>
        //         <div id="navMenu">
        //             <div>
        //                 <a href="admin.html">HOME</a>
        //                 <a href="admin.html">FUND A PROJECT</a>
        //                 <a href="admin.html">SHOP</a>
        //                 <a href="admin.html">NEWS</a>
        //                 <a href="admin.html">ARTISTS</a>
        //                 <SearchInput />
        //                 <LoginButton />
        //                 <Link className="navbar-item" to="/">MY ACCOUNT</Link>
        //                 <CartButton />
        //             </div>

        //         </div>
        //     </div>
        // </nav>

    )
}

export default TopNav
