
import SubscriptionsPanel from './FanExperience/extras/SubscriptionsPanel';
import ProjectsPanel from './FanExperience/extras/ProjectsPanel';
import LatestUpdatePanel from './FanExperience/extras/LatestUpdatePanel';
import UnconditionalSupportPanel from './FanExperience/extras/UnconditionalSupportPanel';


const UserNav = (props) => {

    function handleChange(e) {
        console.log("onchange", e)
        window.location.href = e;
    }

    console.log("props in usernav", props)
    return (
        <section className="zl_left_sidebar_section">
        <div className="zl_left_sidebar_content">
            <div className="zl_mobile_left_sidebar_content">
                <h4 className="zl_left_siebar_responsive_small_heading">MY ACCOUNT</h4>
                <div className="zl_mobile_left_sidebar_nav_links">
                        <select className="zl_mobile_left_sidebar_nav_links_select" onChange={(event) => handleChange(event.target.value)}>
                            <option value="/myaccount">--- Select ---</option>
                            <option value="/myaccount">My Projects</option>
                            <option value="/subscriptions">Newsletters</option>
                            <option value="/orderhistory">Orders and Shipping</option>
                            <option value="/changeemail">Change Email</option>
                       {/* <option value="Change Password">Change Password</option>*/}
                        {/*<option value="Redeem Sharecard">Redeem Sharecard</option>*/}
                        {/*<option value="My Cart">My Cart</option>*/}
                        {/*<option value="Log Out">Log Out</option>*/}
                    </select>
                </div>
            </div>
            <ul className="zl_left_sidebar_nav">
                <h4 className="zl_left_siebar_desktop_small_heading">MY ACCOUNT</h4>
                <li className="zl_left_sidebar_items">
                    <a href="/myaccount" className="zl_left_sidebar_link block text-base text-color3 font-bold py-[7.5px] px-[10px] rounded-[3px] tracking-[-0.18px] bg-color12 hover:bg-color12 hover:text-color3">My Projects</a>
                </li>
                <li className="zl_left_sidebar_items">
                    <a href="/subscriptions" className="zl_left_sidebar_link">Newsletters</a>
                </li>
                <li className="zl_left_sidebar_items">
                    <a href="/orderhistory" className="zl_left_sidebar_link">Orders and Shipping</a>
                </li>
                <li className="zl_left_sidebar_items">
                    <a href="/changeemail" className="zl_left_sidebar_link">Change Email</a>
                </li>
                <li className="zl_left_sidebar_items">
                        <a href="../../Account/ForgotPassword?email=" target="_blank" className="zl_left_sidebar_link">Change Password</a>
                </li>
                {/*<li className="zl_left_sidebar_items">*/}
                {/*    <a href="redeemsharecard" className="zl_left_sidebar_link">Redeem Sharecard</a>*/}
                {/*</li>*/}
            </ul>
            {/*<ul className="zl_left_sidebar_nav zl_left_sidebar_second_nav">*/}
            {/*    <li className="zl_left_sidebar_items">*/}
            {/*        <a href=" " className="zl_left_sidebar_link">My Cart</a>*/}
            {/*    </li>*/}
            {/*    <li className="zl_left_sidebar_items">*/}
            {/*        <a href=" " className="zl_left_sidebar_link">Log Out</a>*/}
            {/*    </li>*/}
            {/*</ul>*/}
         
            <div className="zl_bottom_content">
                <div className="zl_bottom_label_content">
                        <SubscriptionsPanel showpanel={props.showsubscriptions} count={props.subcount} />
                        <ProjectsPanel count={props.projectcount} showpanel={props.showprojects} />
                        <LatestUpdatePanel showpanel={props.showlastupdate} />
                </div>

                    <UnconditionalSupportPanel showpanel={props.showunconditional} />
               
            </div>
        </div>


        
       
      
       
       


    </section>

      
    
    )
}

export default UserNav
