import React from 'react'


const LessIsMore = ({ text, maxlength = 100 }) => {

    const [showLess, setShowLess] = React.useState(true);

    if (text.length < maxlength) {
        return <p className="zl_card_peregraph">{text}</p>;
    }
   
    return (
        <span>
            <p className="zl_card_peregraph"
                dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, maxlength)}...` : text,
                }}
            ></p>
            <a
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => setShowLess(!showLess)}
            >
                &nbsp;read {showLess ? "more" : "less"}
            </a>
        </span>
    )
}


export default LessIsMore

