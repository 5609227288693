import React, { useState } from "react";

//import PropTypes from 'prop-types'
export function Contact(props) {

 
    const [ inputField, setInputField] = useState({
        ArtistId: props.offer[0].artistId,
        FullName: '',
        Message: '',
        messageSent: false,
        messageReturn: ''
        
    });
    // Contact = props => {

    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

        const handleSubmit = (evt) => {
            evt.preventDefault();
          
            fetch( `/../fans/SendMessageJson?fullname=${inputField.FullName}&message=${inputField.Message}&artistId=${inputField.ArtistId}`, {
                method: 'GET'
            }).then(r => r.json()).then(res => {
                console.log("message response", res)
                inputField.messageSent = res.messageSent;
                inputField.messageReturn = res.returnMessage;
                inputField.messageSent = res.messageSent;
                console.log("message sent", inputField.messageSent)
                console.log("message returned", inputField.messageReturn)
                alert(inputField.messageReturn)
            });
        }

    if (inputField.messageSent==true) {
        return (

            <main className="zl_main_body_content zl_home_page_body zl_contact_page_body">
              
                        <form className="zl_contact_form_section" >
                            <div className="zl_contact_form_step_one">
                                <h3>SUCCESS!</h3>
                                <p>
                                    {inputField.messageReturn}here is a test message
                                </p>
                            </div>

                        </form>

               
            </main>
        )
    } else {
        return (
            <main className="zl_main_body_content zl_home_page_body zl_contact_page_body">
               
               
                    <form onSubmit={handleSubmit} className="zl_contact_form_section" >
                        <div className="zl_contact_form_step_one">
                                <h3>SEND A MESSAGE</h3>
                                <p>
                                    Use this form to send a message directly to the artist.  Please note that your email address will be provided to the artist along with the message.
                                </p>
                            </div>
                        <div className="zl_contact_form_step_two">
                            <div className="zl_contact_form_step_two_input_content">
                                <div className="zl_contact_form_step_two_input">
                                        <label>Your Name</label>
                                        <input type="text" name="FullName" value={inputField.FullName} onChange={inputsHandler} />
                                    </div>
                                <div className="zl_contact_form_step_two_input">
                                        <label>Message</label>
                                        <input type="textarea" name="Message" value={inputField.Message} onChange={inputsHandler} />
                                    </div>

                                </div>
                            <div className="zl_contact_form_bottom_btn_content">
                            <input type="submit" className="zl_contact_form_login_btn" value="SEND" />
                            <input type="hidden" name="ArtistId" value={props.offer[0].artistId} />
                                </div>
                            </div>
                        </form>

                
            </main>
        )
    }
   // }
}
// Contact.propTypes = {

// }

export default Contact
