import React from 'react'


const FileType = props => {
   
    return (
       
    <span  className="zl_card_video_type_label">{props.type} {/* TODO: remove the leading . from the prop type and create a switch statement to choose the color */}</span>
         
    )
}



export default FileType
