//import PropTypes from 'prop-types';

const Galleries = (props) => {
    //https://stackblitz.com/edit/react-hook-form-crud-example?file=users%2FAddEdit.jsx
    return (
        <>
            {props.offer.map((item, j) => (
                <div key={j} className="zl_mainbody_right_content">
                    <div key="gallery_section" className="zl_full_video_section">
                        <h1 className="zl_page_main_heading">{item.name}</h1>

                        {item.hasGalleries > 0 &&
                            <h2 className="zl_page_main_sub_heading">
                                GALLERIES <span>({item.hasGalleries})</span>
                            </h2>
                        }
                        {item.hasGalleries === 0 &&

                            <h2 className="zl_page_main_sub_heading">
                                No galleries have been posted yet.  Please check back soon!

                            </h2>

                        }
                    </div>

                   

                    <div className="zl_galleries_image_content">
                        {item.galleryShows.map((dl, i) => (
                            <div key={`${j}_${i}`} className="zl_galleries_image_items">
                                <div className="zl_galleries_image_items_img_hover">
                                    <img src={dl.imageSourceLg} alt={dl.title} />
                                    {dl.playlist !== null && (
                                        <div
                                            onClick={() => {
                                                props.setGalleryId(i);
                                                props.setPage("showGallery");
                                            }}
                                            className="zl_galleries_image_items_hover"
                                        >
                                            <p>VIEW GALLERY</p>
                                        </div>
                                    )}
                                </div>
                                <div className="zl_galleries_image_text">
                                    <div className="zl_galleries_image_heading_peregraph">
                                        <h3>{dl.title}</h3>
                                        <p>Posted 2 days ago</p>
                                    </div>
                                    <div className="zl_galleries_image_date_text">
                                        {dl.playlist === null ? 0 : dl?.playlist?.length}{" "}
                                        <span>Images</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            ))}
        </>
    );
};

// Galleries.propTypes = {

// }

export default Galleries;
