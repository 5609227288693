
//import PropTypes from 'prop-types'

const News = props => {
    return (
        <div key="news_main" className="zl_mainbody_right_content">
          {props.offer.map(item => (
            <div key={item.id} className="zl_full_video_section">
                <div key="news_section" className="zl_full_video_section">
                      <h1 className="zl_page_main_heading">{item.name}</h1>


                      {item.hasNews > 0 &&
                          <h2 className="zl_page_main_sub_heading">NEWS <span>({item.hasNews})</span></h2>
                      }
                      {item.hasNews === 0 &&

                          <h2 className="zl_page_main_sub_heading">
                              No news has been posted yet.  Please check back soon!

                          </h2>

                      }

                    
                </div>
                  <div key="news_item" className="zl_news_section">
                        {/* <h3>News Start Here ({item.hasNews})</h3>  */}
                    {item.newses.map(dl =>(
                      <div key={dl.id} className="zl_news_text">
                          <img src={dl.imageSourceLg} alt={dl.title} />
                          <span className="zl_news_date_text">{dl.newsDate}</span>
                          <h2 className="zl_news_heading_text">{dl.title}</h2>
                          <p  className="zl_news_first_peregraph"></p>
                          <p>{dl.body}</p>
                      </div>
                    ))}
                  </div>
            </div>
          ))}
        </div>
    )
}

// News.propTypes = {

// }

export default News
