import React from 'react'
//import PropTypes from 'prop-types'

const ViewAllButton = props => {
    return (
        <div className="zl_all_download_btn">
            {props.hasItems > props.threshold &&
                <span onClick={() => props.setPage(props.page)} className="zl_all_download_link">{props.buttonText}</span>
            }
        </div>
    )
}

// ViewAllDownloadsButton.propTypes = {

// }

export default ViewAllButton

