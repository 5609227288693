
//import PropTypes from 'prop-types'

const Footer = props => {
    return (
       
        <section className="zl_footer_section">
            <div className="container">
                <footer className="zl_footer_content">
                    <h2 className="zl_footer_heading">share the experience</h2>
                    {/*<div className="zl_footer_copyright_menu_content">*/}
                    {/*    <p className="zl_footer_copyright_text">ArtistShare® - First In Fan Funding. 2016 ArtistShare.</p>*/}
                    {/*    <ul className="zl_footer_menu_nav">*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Contact Us</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Start a Project</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Profiles</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Partners</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Our Mission</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Redeem Sharecard</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Help</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">Privacy</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link">awards</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="zl_footer_menu_items">*/}
                    {/*            <a href="index.html" className="zl_footer_menu_link block md:text-[8px] text-xs text-color11 tracking-[0.12px] px-[14px]">Terms</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </footer>
            </div>
        </section>
   
    )
}

// Footer.propTypes = {

// }

export default Footer
