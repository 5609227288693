import React, { useState } from 'react'
//import PropTypes from 'prop-types'
import Dashboard from './Dashboard'
import Watch from './Watch'
import Downloads from './Downloads'
import Galleries from './Galleries'
import News from './News'
import Contact from './Contact'
import GalleryPlayer from './GalleryPlayer';



const Experience = ({ offer, selection, setPage }) => {
    const [galleryId, setGalleryId] = useState(0);
    return (
        <>
            {selection.showDashboard ? <Dashboard offer={offer} setPage={setPage} /> : null}
            {selection.showWatch ? <Watch offer={offer} /> : null}
            {selection.showDownloads ? <Downloads offer={offer} /> : null}
            {selection.showGalleries ? <Galleries offer={offer} setPage={setPage} setGalleryId={setGalleryId} /> : null}
            {selection.showNews ? <News offer={offer} /> : null}
            {selection.showContact ? <Contact offer={offer} /> : null}
            {selection.showGallery ? <GalleryPlayer offer={offer} setPage={setPage} galleryId={galleryId} /> : null}
        </>
    )
}

// Experience.propTypes = {

// }

export default Experience
