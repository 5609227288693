import React from 'react'
//import PropTypes from 'prop-types'

const UnconditionalSupportPanel = props => {
    if (props.showpanel === 'true') {
        return (
            <div className="zl_unconditional_support_box">
                <svg className="zl_unconditional_support_box_img" xmlns="http://www.w3.org/2000/svg" width="47" height="53" viewBox="0 0 47 53">
                    <g fill="none" fillRule="evenodd">
                        <g fillRule="nonzero" transform="translate(-194.000000, -1015.000000) translate(194.000000, 1015.000000)">
                            <path fill="#75CFFF" d="M3.8 15L3.8 37.4 23.2 48.6 42.6 37.4 42.6 15 23.2 3.8z" opacity=".412" />
                            <circle cx="23.2" cy="4" r="4" fill="#009BEF" />
                            <circle cx="23.2" cy="48.4" r="4" fill="#009BEF" />
                            <circle cx="4" cy="15.1" r="4" fill="#009BEF" />
                            <circle cx="42.4" cy="37.3" r="4" fill="#009BEF" />
                            <circle cx="4" cy="37.3" r="4" fill="#009BEF" />
                            <circle cx="42.4" cy="15.1" r="4" fill="#009BEF" />
                            <path fill="#009BEF" d="M23.2 14.2c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12z" />
                            <path fill="#FFF"
                                d="M24.2 31.8v1.8h-1.7v-1.8c-1.2-.2-2.3-.7-3.3-1.7l1.4-1.6c.9.9 2 1.3 2.9 1.3.8 0 1.4-.4 1.4-1 0-.7-.5-1-1.9-1.4-1.9-.6-3.5-1.2-3.5-3.3 0-1.7 1.1-2.9 3-3.2V19h1.7v2c1 .2 2.1.7 2.8 1.3l-1.3 1.6c-.9-.6-1.8-.9-2.5-.9-.8 0-1.3.3-1.3.9 0 .6.6.9 1.7 1.3 2.2.7 3.7 1.2 3.7 3.4-.1 1.7-1.3 2.9-3.1 3.2z" />
                        </g>
                    </g>
                </svg>
                <h3 className="zl_unconditional_support_box_heading">What is unconditional support?</h3>
                <p className="zl_unconditional_support_box_peregraph">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
            </div>
        )
    } else {
        return(<></>)
    }
}

// UnconditionalSupportPanel.propTypes = {

// }

export default UnconditionalSupportPanel
