
import UserNav from '../components/UserNav';

function RedeemSharecard() {
    return (
      <main className="zl_main_body_content zl_home_page_body zl_redeem_sharecard_page_body">
      <div className="container">
        <UserNav/>
        <div className="zl_mainbody_right_content">
                    <h2 className="zl_mainbody_right_content_main_heading">Redeem Sharecard Number</h2>
                    <p className="zl_mainbody_right_content_main_peregraph">
                        ShareCard numbers allow exclusive access to Artistshare participant offers. Existing users can login to their
                        Artistshare account and new users can easily create a new account by following the steps below
                    </p>
                    <form className="zl_redeem_form_section">
                        <div className="zl_redeem_form_step_one">
                            <span>STEP 1</span>
                            <h3>Enter your number here</h3>
                            <div className="zl_redeem_form_step_one_inputs">
                                <input type="text" />
                                <input type="text" />
                                <input type="text" />
                            </div>
                            <p>
                                Please be sure that your browser accepts cookies and that javascript is enabled. If you have
                                any trouble, please contact us anytime at <a href="mailto:support@artistshare.com">support@artistshare.com</a>. We will be happy to assist!
                            </p>
                        </div>
                        <div className="zl_redeem_form_step_two">
                            <div className="zl_redeem_form_step_two_top_btns_content">
                                <button type="button" className="zl_redeem_form_step_two_top_btn active">I am an existing user.</button>
                                <button type="button" className="zl_redeem_form_step_two_top_btn">I am a new user.</button>
                            </div>
                            <span>STEP 2</span>
                            <h3>LOGIN RETURNING USERS</h3>
                            <div className="zl_redeem_form_step_two_input_content">
                                <div className="zl_redeem_form_step_two_input">
                                    <label>E-Mail/Username</label>
                                    <input type="email" />
                                </div>
                                <div className="zl_redeem_form_step_two_input">
                                    <label>Password</label>
                                    <input type="password" />
                                </div>
                                <div className="zl_rememberme_text_input">
                                    <input type="checkbox" id="zl_rememberme_text" />
                                    <label htmlFor="zl_rememberme_text">Remember Me</label>
                                </div>
                            </div>
                            <div className="zl_redeem_form_bottom_btn_content">
                                <button type="button" className="zl_redeem_form_login_btn">Login</button>
                                <a href=" " className="zl_redeem_form_forgot_btn">Forgot Password?</a>
                            </div>
                        </div>
                    </form>
                    
                </div>
      </div>
      </main>
    );
  }
  
  export default RedeemSharecard;