import React, {useState, useEffect} from 'react';
import UserNav from '../components/UserNav';

function Subscriptions() {

 
  useEffect(()=>{
    fetchItems();
    },[]);

const [items,setItems] = useState([]);

const fetchItems= async () =>{
    const res = await fetch('fans/artistsubscriptionsarray');

    const items = await res.json();

    console.log("items before update", items)

    setItems(items);

};

    const updateItem = async (artistId, subscribe) => {

        const update = await fetch(`/../../fans/artistsubscriptionsupdate?artistId=${artistId}&optOut=${!subscribe}`);
        console.log(update);
        //find the checkbox by id and update the checked value
        document.getElementById(artistId).checked = !subscribe;

        console.log(artistId);

        // loop over the list and find the provided artistid.
        let updatedList = items.map(item => {
            if (item.artistId == artistId) {
              
                return { ...item, optOut: !item.optOut };
            } else if(item.artistId != artistId) {
                return { ...item};
            }

            return items; 
            
        });

  
        setItems(updatedList);
    
        console.log("artistId", artistId);
        console.log("current state", subscribe);
    };


    return (
        <main className="zl_flex_grow zl_main_body_content zl_subscriptions_page_body">
      <div className="container">
                <UserNav items={items} />
    
                <div className="zl_mainbody_right_content">
                    
                    <h2 className="zl_mainbody_right_content_main_heading">Newsletters and Updates  </h2>
                  {/*  <h4 className="zl_subscriptions_artist_status zl_subscriptions_artist_status_unsubscribe">Receive important email announcements and project updates from the artists</h4>*/}
                   
      <div className="zl_artist_subscriptions">
      <div className="zl_subscriptions_artist_status_content">
          <div className="zl_subscriptions_artist_status_heading_text">
              <h4>ARTIST</h4>
              <h4>STATUS</h4>
          </div>
          {items.map(item => (
                <div className="zl_subscriptions_artist_status_heading">
                    <div  className="zl_subscriptions_artist_heading">
                        <h3 key={item.artistId}>{item.artistName}</h3>
                    </div>
                    <div  className="zl_subscriptions_artist_status">
                      <input onChange={e => updateItem(item.artistId, item.optOut)} checked={!item.optOut} key={item.artistId} type="checkbox" id={item.artistId} />
                      <label htmlFor={item.artistId} className="zl_subscriptions_artist_status_unsubscribe">Unsubscribed</label>
                      <label htmlFor={item.artistId} className="zl_subscriptions_artist_status_subscribe">Subscribed</label>
                    </div>
                  </div>
              ))}
          </div>
      
     
      </div>
      
      </div>
      </div>
      </main>
    );
  }
  
  export default Subscriptions;