import { useState, useEffect } from "react";
import VideoPlayer from "./VideoPlayer";
import FileType from "./extras/FileType";
import LessIsMore from "./extras/LessIsMore";

const Watch = (props) => {

    console.log("videos", props.offer[0].videoShows[1])
    const [video, setVideo] = useState(props.offer[0].videoShows[0]);
    const [next, setNext] = useState(1);
    const videoHandler = (videoNext, next) => {
        setVideo(videoNext);
        let i = next + 1;
        setNext(i);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [video]);

    return (
        <div key="watch_container" className="zl_mainbody_right_content">
            {props.offer.map((item) => {
                return (
                    <div key={item.id} className="zl_full_video_section">
                        <h1 className="zl_page_main_heading">{item.name}</h1>

                        {item.hasVideo > 0 &&
                            <h2 className="zl_page_main_sub_heading">
                                WATCH & LISTEN <span>({item.hasVideo + item.hasAudio})</span>
                            </h2>
                        }
                        {item.hasVideo === 0 &&

                            <h2 className="zl_page_main_sub_heading">
                                No video has been posted yet.  Please check back soon!
                            </h2>
                        }
                     
                        <div className="zl_full_video_content">
                           
                            <VideoPlayer mediaShow={video} />
                            {item.videoShows.length !== next && ( 
                                <div
                                    className="zl_next_video_play_content"
                                    onClick={() => videoHandler(item.videoShows[next], next)} 
                                >
                                    <img
                                        src={item.videoShows[next]?.imageSourceSm}
                                        alt={item.videoShows[next]?.title}
                                    />
                                    <div key={item.videoShows[next]?.id}>
                                        <div className="zl_next_video_play_text">
                                            <span> PLAY NEXT</span>
                                            <h3>{item.videoShows[next]?.title}</h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <h2 className="zl_mainbody_right_content_main_heading">
                            All Videos and Audio
                        </h2>
                        <div className="zl_column_row zl_recent_download_row">
                            {item.videoShows.map((dl, i) => (
                                <div
                                    className="zl_card"
                                    key={dl.id}
                                   
                                >
                                    <div className="zl_card_video_img_content w-full h-full bg-[url('https://www.kindacode.com/wp-content/uploads/2022/06/night-sky.jpeg')] bg-cover bg-center blur-md" onClick={() => videoHandler(dl, i)}>
                                       
                                         <img src={dl.imageSourceLg} alt={dl.title} />
                                        <button className="zl_video_play_btn"></button>
                                        <FileType type={dl.mediaType} />
                                    </div>
                                    <div className="zl_card_body">
                                        <h2 className="zl_card_heading">{dl.title}</h2>
                                        <LessIsMore text={dl.description} />
                                       
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Watch;
