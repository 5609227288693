import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-playlist";
import "videojs-contrib-quality-levels";
import "videojs-extra-buttons";
import "videojs-extra-buttons/dist/videojs-extra-buttons.css";

const VideoPlayer = ({ mediaShow }) => {
    let player = useRef();

    useEffect(() => {
      
        updateVideo(mediaShow);
        document.body.scrollTo(0, 0);
       
    }, [mediaShow]);




    useEffect(() => {
        return () => {
            player?.current.dispose();
        };
    }, []);

  

    const updateVideo = (mediaShow) => {
        player.current = videojs("preview-player");
        player.current.reset();
        let tempVideoList = [];

       
        mediaShow.playlist?.length > 0
            ? mediaShow.playlist?.map((element) => {
               // var newMediaUrl =element.mediaUrl;
                return tempVideoList.push({
                    sources: [
                        {
                            src: element.mediaUrl , 
                            type: "video/mp4",
                        },
                    ],
                    poster: mediaShow.imageSourceLg,
                    thumbnail: mediaShow.imageSourceSm,
                });

            })
            : tempVideoList.push({
                sources: [],
                poster: mediaShow.imageSourceLg,
                thumbnail: mediaShow.imageSourceSm,
            });

        console.log("tempVideoList", mediaShow);

        setTimeout(() => {
            player.current.playlist(tempVideoList);
            player.current.playlist.first(); //added April 6, 2022 to fix alternating video failing
            player.current.playlist.autoadvance(0);
        }, 500);
    };

    return (
        <div className="main-preview-player">
            <video
                id="preview-player"
                className="video-js vjs-fluid zl_full_video"
                controls
                preload="auto"
            >
                <p className="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to
                    a web browser that{" "}
                    <a href="http://videojs.com/html5-video-support/">
                        supports HTML5 video
                    </a>
                </p>
            </video>
            {/* //The div below is just the area in which i want to have playlist
        video's thumbnail //which on click will play the video connected to that
        thumbnail. */}
            <div className="vjs-playlist"></div>
            <span className="zl_video_title">{mediaShow?.title}</span>
        </div>
    );
};

export default VideoPlayer;
