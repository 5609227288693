
//import PropTypes from 'prop-types'
//import DownloadModal from './DownloadModal';
import FileType from './extras/FileType';



const Downloads = props => {


    //const handleSubmit = (e) => {
    //    alert('A name was submitted: ' + this.state.value);
    //    e.preventDefault();
    //}


    return (
      <>
      {props.offer.map(item => (
        <div key="downloads_main" className="zl_mainbody_right_content">
            <div className="zl_full_video_section">
                  <h1 className="zl_page_main_heading">{item.name}</h1>

                  {item.hasDownloads > 0 &&
                      <h2 className="zl_page_main_sub_heading">DOWNLOADS <span>({item.hasDownloads})</span></h2>
                  }
                  {item.hasDownloads === 0 &&

                      <h2 className="zl_page_main_sub_heading">
                          No downloads have been posted yet.  Please check back soon!
                      </h2>
                  }
            </div>
               
              <div key={item.id} className="zl_column_row zl_recent_download_row">

                  {item.downloads.map(dl =>(

                  <div className="zl_card" key={dl.id}>
                    <div className="zl_card_body">
                          <FileType type={dl.fileType}/>
                          <h2 className="zl_card_heading">{dl.title}</h2>
                          <p className="zl_card_peregraph">{dl.description}</p>
                          <div className="zl_card_buttons">
                              <p className="zl_video_size"> {dl.fileSizeDisplay}</p>
                                  <a href={dl.fileUrl} className="zl_card_download_btn">DOWNLOAD</a>
                                  {/* <DownloadModal dl={dl} /> */}
                          </div>
                      </div>
                  </div>

                  ))}   

              </div>  
            
        </div>
        ))}
        </>
      )
}



// Downloads.propTypes = {

// }

export default Downloads
