import React, { useEffect } from "react";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";

// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

const GalleryPlayer = (props) => {
    // const onInit = useCallback((detail) => {
    //     if (detail) {
    //         lightGallery.current = detail.instance;
    //     }
    // }, []);

    const getItems = () => {
        return props.offer[0]?.galleryShows[props.galleryId]?.playlist.map(
            (item, i) => {
                return (
                    <a
                        key={item.fileId}
                        // data-lg-size={i%5 === 0 ? '1806-1790' : '1400-1400'}
                        className={`gallery-item img-responsive ${i % 5 === 0 ? "zl_col_12" : "zl_col_6"
                            }`}
                        href={item.mediaUrl}
                    >
                        {/* <div className={`img-responsive ${i % 5 === 0 ? 'zl_col_12' : 'zl_col_7'}`}> */}
                        <div className="zl_image_gallery_items">
                            <img src={item.mediaUrl} alt="media" />
                        </div>
                        {/* </div> */}
                    </a>
                );
            }
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="zl_mainbody_right_content">
                <div className="zl_full_video_section">
                    <h1 className="zl_page_main_heading">
                        {props.offer[0]?.galleryShows[props.galleryId]?.title}
                    </h1>
                    <div className="zl_galleries_player_heading_back_btn">
                        <h2 className="zl_page_main_sub_heading">GALLERY</h2>
                        <div
                            onClick={() => props.setPage("showGalleries")}
                            className="zl_galleries_player_back_btn"
                        >
                            back
                        </div>
                    </div>
                </div>
                <div className="zl_image_gallery_section">
                    <div className="zl_image_gallery_content" id="lightgallery">
                        <div className="zl_col_12">
                        <div className="zl_image_gallery_items">
                            <LightGallery
                                    plugins={[lgZoom]}
                                    thumbnail={true}
                                    animateThumb={false}
                                    download={false}
                                    share={false}
                                mobileSettings={{
                                    controls: false,
                                    showCloseIcon: true,
                                    download: false,
                                    share: false
                                }}
                            >
                         
                                {getItems()}
                         
                            </LightGallery>
                            </div>
                            </div>
                    </div>
                </div>
                <div className="zl_responsive_bottom_content">
                    <h4 className="zl_left_siebar_desktop_small_heading">
                        LATEST PARTICIPANTS
                    </h4>
                    <ul className="zl_letest_participant_nav">
                        <li className="zl_letest_participant_item">
                            <div className="zl_letest_participant_link">Michael J</div>
                        </li>
                        <li className="zl_letest_participant_item">
                            <div className="zl_letest_participant_link">Sarah Q</div>
                        </li>
                        <li className="zl_letest_participant_item">
                            <div className="zl_letest_participant_link">Edward L</div>
                        </li>
                        <li className="zl_letest_participant_item">
                            <div className="zl_letest_participant_link">Mike J</div>
                        </li>
                        <li className="zl_letest_participant_item">
                            <div className="zl_letest_participant_link">Samantha S</div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default GalleryPlayer;
