import TopNav from './components/TopNav';
import OrderHistory from './pages/OrderHistory';
import FanExperience from './pages/FanExperience';
import RedeemSharecard from './pages/RedeemSharecard';
import Subscriptions from './pages/Subscriptions';
import ChangePassword from './pages/ChangePassword';
import ChangeEmail from './pages/ChangeEmail';
import MyProjects from './pages/MyProjects';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Footer from './components/Footer';





function App() {
  return (
    <Router basename="/fanapp/myaccount">
   <div class="zl_flex">
    
      <TopNav/>
    
      <Route path="/fanexperience/:id" component={FanExperience}></Route>
      <Route path="/orderhistory" component={OrderHistory}></Route>
      <Route path="/redeemsharecard" component={RedeemSharecard}></Route>
      <Route path="/subscriptions" component={Subscriptions}></Route>
      <Route path="/changepassword" exact component={ChangePassword}></Route>
      <Route path="/changeemail" exact component={ChangeEmail}></Route>
      <Route path="/myaccount" exact component={MyProjects}></Route>

      <Footer />
   
      </div>
    </Router>
  );
}

export default App;
