
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MakeItUnconditional from "../components/MakeItUnconditional";
import UserNav from "../components/UserNav";

function MyProjects() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch("fans/MyAccountJson", {
            method: 'GET',
            credentials: 'include'
        } )
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                    console.log("result", result)
                },

                (error) => {
                    setIsLoaded(true);
                    setError(error);
                    
                }
        );
        return (null);
       
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="container">

            <div className="zl_mainbody_right_content">
                <h2 className="zl_mainbody_right_content_main_heading">
                    Loading...
                </h2>

            </div>
        </div>;
    } else if (items.length > 0) {
        return (
            <>
                <main
                    id="mainProjects"
                    className="zl_main_body_content zl_myproject_page_body"
                >
                    <div className="container">
                        <UserNav projectcount={items.offers} showprojects='true' subcount={items.offers} showsubscriptions='true' lastdateupdate='7' showlastupdate='false' showunconditional='false' />
                        {console.log("items offers object", items)}
                        <div className="zl_mainbody_right_content">
                            <h2 className="zl_mainbody_right_content_main_heading">
                                My Projects
                            </h2>
                            <div className="zl_column_row">
                                {items.map((item, i) => (

                                    <div key={i} id={item.index} className="zl_card">
                                        <div className="zl_card_img_content">
                                        <img
                                            src={item.imageSourceLg}
                                            alt={item.name}
                                            className="zl_card_img"
                                        />
                                        </div>
                                        <div className="zl_card_body">
                                            <h2 className="zl_card_heading">
                                                {item.artistName} - {item.name}
                                            </h2>
                                            <p className="zl_card_date_text">
                                                Purchased {item.purchasedDateDisplay}
                                            </p>
                                            <div className="zl_card_buttons">
                                                {/*  <MakeItUnconditional orderId={item.Id} />*/}
                                                <Link
                                                    to={`/fanexperience/${item.id}`}
                                                    className="zl_card_viewall_btn"
                                                >
                                                    LOAD EXPERIENCE
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                               
                            </div>

                        </div>
                    </div>
                </main>
            </>
        );
    } else {
        return <>
            <main
                id="mainProjects"
                className="zl_flex_grow zl_main_body_content zl_myproject_page_body"
            >
                <div className="container">
                    <UserNav projectcount={items.offers} showprojects='true' subcount={items.offers} showsubscriptions='true' lastdateupdate='7' showlastupdate='false' showunconditional='false' />
            
                    <div className="zl_mainbody_right_content">
                        <h2 className="zl_mainbody_right_content_main_heading">
                            My Projects
                        </h2>
                        <div className="zl_column_row">
                           
                                <div  className="zl_card">
                                <img
                                    src="../images/AS-logo-white.png"
                                    alt="logo"
                                    className="zl_card_img"
                                />
                                    <div className="zl_card_body">
                                        <h2 className="zl_card_heading">
                                       Welcome!
                                    </h2>

                                    <p className="zl_card_date_text">
                                        You are not currently participating in any projects.
                                    </p>
                                        
                                        <div className="zl_card_buttons">
                                        
                                        <a href="../../home/features" className="zl_card_viewall_btn">BROWSE PROJECTS</a>
                                        </div>
                                    </div>
                                </div>
                           
                        </div>

                    </div>
                </div>
            </main>
        </>;
                            
                       
   
        
    }
}

export default MyProjects;
