import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import UserNav from '../components/UserNav';

function OrderHistory() {

  useEffect(()=>{
    fetchItems();
    },[]);

const [items,setItems] = useState([]);

const fetchItems= async () =>{
const res = await fetch('fans/shippinghistoryarray');

const items =  await res.json();
console.log("shipping history items",items);
setItems(items);
};

    return (
        <>
            <main
                id="mainProjects"
                className="zl_flex_grow zl_main_body_content zl_myproject_page_body"
            >
                <div className="container">
                    <UserNav items={items} />

                    <div className="zl_mainbody_right_content">
                        <h2 className="zl_mainbody_right_content_main_heading">
                          Orders and Shipping
                        </h2>
                        <div className="zl_column_row">

                            {items.map((item, i) => (
                                <div key={i} id={item.index} className="zl_card">
                                    <h2 className="zl_card_heading">
                                        {item.artistName} - {item.itemName}
                                    </h2>
                                    <img
                                        src={item.imageSourceMd}
                                        alt={item.name}
                                        className="zl_card_img"
                                    />
                                    <div className="zl_card_body">
                                        
                                        <div className="zl_card_date_text">
                                            purchase date<br/> {item.datePurchasedDisplay}
                                       
                                        {(item.shipYn === true && item.shippedYn === true) &&
                                            <>
                                            <div>
                                                shipping status:<br/>
                                            </div>
                                            <div>Shipped on  {item.dateShippedDisplay}</div>
                                            <div><a href={item.trackingUrl} target="_blank">track via {item.shippingCompany}</a></div>
                                            </>
                                           
                                            }
                                        </div>


                                        {item.shipYn === true && item.shippedYn === false &&
                                            <>
                                            <div>
                                            shipping status 
                                            </div>
                                            <div>shipping in progress.  tracking info pending.</div>
                                            </>
                                        }

                                        {item.shipYn === false &&
                                            <p className="zl_card_date_text">
                                               no shipping required
                                            </p>
                                        }

                                      

                                        <div className="zl_card_buttons">
                                           
                                            <a href={`/../../checkout/receipt?orderId=${item.orderId}`} target="_blank" className="zl_card_viewall_btn">VIEW RECEIPT</a>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>
                </div>
            </main>


</>
    
    );
  }
  
  export default OrderHistory;