import React from 'react';
//import PropTypes from 'prop-types'

const SideNavMobile = props => {

//const [selectedOption] = useState([]);

function handleChange(e) {
        props.setPage(e.target.value);
}
    return (
     <select onChange={handleChange} className="zl_mobile_left_sidebar_nav_links_select">
        <option value="showDashboard">Dashboard</option>
        <option value="showWatch">Watch & Listen</option>
        <option value="showGalleries">Galleries</option>
        <option value="showNews">News</option>
        <option value="showDownloads">Downloads</option>
        <option value="showContact">Contact</option>
    </select>
    )
}

// SideNavMobile.propTypes = {

// }

export default SideNavMobile
