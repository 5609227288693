import React from 'react'
//import PropTypes from 'prop-types'

const ProjectsPanel = props => {
    console.log("props", props.count)
    if (props.showpanel === 'true') {
        return (
            <div className="zl_bottom_label_projects zl_bottom_yellow_label_content">
                <h3 className="zl_bottom_label_heading">Projects</h3>
                <h2 className="zl_bottom_label_sub_heading">{props.count}</h2>
            </div>
        )
    } else {
        return (<></>)
    }
}

// ProjectsPanel.propTypes = {

// }

export default ProjectsPanel
