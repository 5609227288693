//import PropTypes from 'prop-types'
import FileType from "./extras/FileType";
import VideoPlayer from "./VideoPlayer";
import ViewAllButton from "./extras/ViewAllButton";

const Dashboard = (props) => {
    return (
        <div key="dash_main" className="zl_mainbody_right_content">
            {props.offer.map((item) => (
                <div key={item.id} className="zl_full_video_section">
                    <h1 className="zl_page_main_heading">{item.name}</h1>
                    {item.videoShows.slice(0, 1).map((vid) => (
                        <div key={vid.id} className="zl_full_video_content">
                            <VideoPlayer mediaShow={vid} />
                            <div className="zl_all_video_link_content">
                                <span
                                    onClick={() => props.setPage("showWatch")}
                                    className="zl_all_video_link"
                                >
                                    VIEW ALL VIDEO
                                </span>
                            </div>
                        </div>
                    ))}


                    {item.hasDownloads > 0 &&
                        <h2 className="zl_mainbody_right_content_main_heading">
                            Recent Downloads
                        </h2>
                    }
                    <div className="zl_column_row zl_recent_download_row">
                        {item.downloads.slice(0, 3).map((dl) => (
                            <div key={dl.id} className="zl_card">
                                <div className="zl_card_body">
                                    <FileType type={dl.fileType} />
                                    <h2 className="zl_card_heading">{dl.title}</h2>
                                    <p className="zl_card_peregraph">
                                        {dl.description}
                                    </p>
                                    <div className="zl_card_buttons">
                                        <p className="zl_video_size">{dl.fileSizeDisplay}</p>
                                        <p className="zl_card_date_text">Released 7/24/2021</p>
                                        <a href={dl.fileUrl} className="zl_card_download_btn">DOWNLOAD</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <ViewAllButton
                        setPage={props.setPage}
                        hasItems={item.hasDownloads}
                        threshold="3"
                        page="showDownloads"
                        buttonText="VIEW ALL DOWNLOADS"
                    />

                    {/*{item.hasGalleries > 0 &&*/}
                    {/*    <h2 className="zl_mainbody_right_content_main_heading">*/}
                    {/*        Recent Galleries*/}
                    {/*    </h2>*/}
                    {/*}*/}
                    {/*<div className="zl_column_row zl_recent_download_row">*/}
                    {/*    {item.galleryShows.slice(0, 3).map((dl) => (*/}
                    {/*        <div key={dl.id} className="zl_card">*/}
                    {/*            <div className="zl_card_body">*/}
                                   
                    {/*                <h2 className="zl_card_heading">{dl.title}</h2>*/}
                    {/*                <p className="zl_card_peregraph">*/}
                    {/*                    {dl.description}*/}
                    {/*                </p>*/}
                    {/*                */}{/*<div className="zl_card_buttons">*/}
                                     
                    {/*                */}{/*    <a href={dl.fileUrl} className="zl_card_download_btn">DOWNLOAD</a>*/}
                    {/*                */}{/*</div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}

                    {/*<ViewAllButton*/}
                    {/*    setPage={props.setPage}*/}
                    {/*    hasItems={item.hasGalleries}*/}
                    {/*    threshold="3"*/}
                    {/*    page="showGalleries"*/}
                    {/*    buttonText="VIEW ALL GALLERIES"*/}
                    {/*/>*/}
                </div>
            ))}
        </div>
    );
};

// Dashboard.propTypes = {

// }

export default Dashboard;
