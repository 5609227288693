import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import LatestParticipantsPanel from "../components/FanExperience/extras/LatestParticipantsPanel";
import Experience from "../components/FanExperience/Experience";
import SideNavMobile from "../components/FanExperience/extras/SideNavMobile";

function FanExperience({ match }) {
    useEffect(() => {
        fetchItem();
        return (null);
    }, []);

    const [offer, setItem] = useState([]);

    const viewSections = {
        showDashboard: true,
        showWatch: false,
        showGalleries: false,
        showNews: false,
        showDownloads: false,
        showContact: false,
        showGallery: false,
    };

    const [currentSection, setCurrentSection] = useState([]);

    function setPage(section) {
        for (var key in viewSections) {
            if (key === section) {
                viewSections[key] = true;
            } else {
                viewSections[key] = false;
            }
            setCurrentSection(viewSections);
        }
    }
    //`/./fans/projectdashboardjson?id=${match.params.id}
    const fetchItem = async () => {
        const fetchItems = await fetch(
            `/./fans/projectdashboardjson?id=${match.params.id}`
          
        );
        const offer = await fetchItems.json();
        setItem(offer);
        console.log("offer object",offer);
        setPage("showDashboard");
    };

    return (
        <main className="zl_flex_grow zl_main_body_content zl_dashboard_page_body">
            <div className="container">
                <section className="zl_left_sidebar_section">
                    <div className="zl_left_sidebar_content">
                        <div className="zl_mobile_left_sidebar_content">
                            <h4 className="zl_left_siebar_responsive_small_heading">
                            {/*    <a href="/myaccount">MY PROJECTS</a>*/}
                                <Link to="/myaccount">MY PROJECTS</Link>
                            </h4>
                            <div className="zl_mobile_left_sidebar_nav_links">
                                <SideNavMobile setPage={setPage} />
                            </div>
                        </div>

                        {offer.map(item => (
                            <ul key="sidebar" className="zl_left_sidebar_nav">
                                <h4 className="zl_left_siebar_desktop_small_heading">
                                    <a href="/myaccount">MY PROJECTS</a>
                                   
                                </h4>

                                <li className="zl_left_sidebar_items">
                                    <span
                                        onClick={() => setPage("showDashboard")}
                                        className="zl_left_sidebar_link"
                                    >
                                        Dashboard
                                    </span>
                                </li>


                                <li className="zl_left_sidebar_items">
                                    <span
                                        className="zl_left_sidebar_link"
                                        onClick={() => setPage("showWatch")}
                                    >
                                        Watch & Listen <span>{item.hasVideo}</span>
                                    </span>
                                </li>

                                <li className="zl_left_sidebar_items">
                                    <span
                                        className="zl_left_sidebar_link"
                                        onClick={() => setPage("showGalleries")}
                                    >
                                        Galleries <span>{item.hasGalleries}</span>
                                    </span>
                                </li>

                                <li className="zl_left_sidebar_items">
                                    <span
                                        className="zl_left_sidebar_link"
                                        onClick={() => setPage("showNews")}
                                    >
                                        News <span>{item.hasNews}</span>
                                    </span>
                                </li>

                                <li className="zl_left_sidebar_items">
                                    <span
                                        className="zl_left_sidebar_link"
                                        onClick={() => setPage("showDownloads")}
                                    >
                                        Downloads <span>{item.hasDownloads}</span>
                                    </span>
                                </li>

                                <li className="zl_left_sidebar_items">
                                    <span
                                        className="zl_left_sidebar_link"
                                        onClick={() => setPage("showContact")}
                                    >
                                        Contact
                                    </span>
                                </li>
                            </ul>
                        ))}
                        ;
                        <LatestParticipantsPanel offer={offer} />
                    </div>
                </section>
                <Experience
                    offer={offer}
                    selection={currentSection}
                    setPage={setPage}
                />
            </div>
        </main>
    );
}

export default FanExperience;
