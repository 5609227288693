import React from 'react'
//import PropTypes from 'prop-types'

const LatestParticipantsPanel = props => {
    return (
        <div key="lp" className="zl_bottom_content">
           <h4 key="lpTitle" className="zl_left_siebar_desktop_small_heading">LATEST PARTICIPANTS</h4>

            {props.offer.map(item => (


                
                <ul key={item.id} className="zl_letest_participant_nav">
            
                {item.participants.map(dl =>(
                    <li key={dl.orderId} className="zl_letest_participant_item">
                        
                        <span className="zl_letest_participant_link">{dl.name}</span>
                  </li>
                ))}
              </ul>
             
         ))}
            
        </div>
    )
}

// LatestParticipantsPanel.propTypes = {

// }

export default LatestParticipantsPanel
