import UserNav from '../components/UserNav';
import React, { useState } from "react";


function ChangeEmail() {

    const [inputField, setInputField] = useState({
        Username: '',
        Message: '',
        messageSent: false,
        errorMessage: ''

    });


    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        fetch(`/../fans/changeusernamejson?email=${inputField.Username}`, {
            method: 'GET'
        }).then(r => r.json()).then(res => {
            console.log("message response", res)
            inputField.messageSent = res.messageSent;
            inputField.errorMessage = res.errorMessage;
            console.log("error message", inputField.errorMessage)
            console.log("message sent", inputField.messageSent)

            setInputField(prevState => ({
                ...prevState,
                ["errorMessage"]: inputField.errorMessage
            }));
           
        });
    }


    if (inputField.messageSent == true) {

        return (
            <main
                id="mainProjects"
                className="zl_flex_grow zl_main_body_content zl_myproject_page_body"
            >
                <div className="container">
                    <UserNav />
                    <div className="zl_mainbody_right_content">

                        <form onSubmit={handleSubmit} className="zl_redeem_form_section" >
                            <div className="zl_redeem_form_step_one">
                                <h3>CHANGE EMAIL</h3>
                                <p>
                                    Use this form to update your email address.  Please note that your email address is also your username and the updated email will be required for login the next time you visit the site.
                                </p>
                            </div>
                            <div className="zl_redeem_form_step_two">
                                <div className="zl_redeem_form_step_two_input_content">

                                    <div className="zl_redeem_form_step_two_input">
                                        <label>{ inputField.errorMessage}</label>

                                    </div>

                                </div>
                               
                            </div>
                        </form>

                    </div>
                </div>
            </main>
        )
    }else {
            return (
                <main
                    id="mainProjects"
                    className="zl_main_body_content zl_myproject_page_body"
                >
                    <div className="container">
                        <UserNav />
                        <div className="zl_mainbody_right_content">
                            <form onSubmit={handleSubmit} className="zl_redeem_form_section" >
                                <div className="zl_redeem_form_step_one">
                                    <h3>CHANGE EMAIL</h3>
                                    <p>
                                        Use this form to update your email address.  Please note that your email address is also your username and the updated email will be required for login the next time you visit the site.
                                    </p>
                                </div>
                                <div className="zl_redeem_form_step_two">
                                    <div className="zl_redeem_form_step_two_input_content">

                                        <div className="zl_redeem_form_step_two_input">
                                            <label>New Email/Username</label>
                                            <input type="text" name="Username" onChange={inputsHandler} />
                                            <label>{inputField.errorMessage}</label>
                                        </div>

                                    </div>
                                    <div className="zl_redeem_form_bottom_btn_content">
                                        <input type="submit" className="zl_redeem_form_login_btn" value="CHANGE" />
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </main>
            )

        }
  }
  
  export default ChangeEmail;