import React from 'react'
//import PropTypes from 'prop-types'

const LatestUpdatePanel = props => {
    if (props.showpanel) {
        return (
            <div className="zl_bottom_label_update">
                <h3 className="zl_bottom_label_heading">Latest Update</h3>
                <h2 className="zl_bottom_label_sub_heading">2 days ago</h2>
            </div>
        )
    } else {
       return(<></>)
    }
}

// LatestUpdatePanel.propTypes = {

// }

export default LatestUpdatePanel
